import { DecorativeImage, Library } from "@libry-content/types";
import type { Library as SchemaOrgLibrary } from "schema-dts";
import { getSiteUrl } from "@libry-content/common";
import { getSchemaOrgImageUrl, getSchemaOrgLibrary, sortLibraries } from "../library/utils";
import { TranslateMicroText, TranslateSanityContent } from "../../utils/hooks/useTranslation";
import { ResolvedSite } from "../../utils/fetchWithCommonDataAndCurrentSiteFilter";

export const getSchemaOrgSiteImage = (site?: ResolvedSite): DecorativeImage | undefined =>
  (site?.logo?.asset && site.logo) || site?.municipality?.coatOfArms;

export const getSiteDescription = (t: TranslateMicroText, ts: TranslateSanityContent, site?: ResolvedSite): string =>
  ts(site?.teaser) || t("Velkommen til {nettstedNavn}", { nettstedNavn: ts(site?.name) });

/**
 * Describe site as a schema.org Library that extends the main library and includes data about the different branches under `department`.
 * https://developers.google.com/search/docs/advanced/structured-data/local-business#multiple-departments
 */
export const getSchemaOrgSite = (
  t: TranslateMicroText,
  ts: TranslateSanityContent,
  libraries: Library[],
  site: ResolvedSite
): Exclude<SchemaOrgLibrary, string> | undefined => {
  const siteImage = getSchemaOrgSiteImage(site);
  const firstLibrary = sortLibraries(site, libraries)?.[0];

  if (!firstLibrary) return undefined;

  return {
    ...getSchemaOrgLibrary(ts, firstLibrary, site),
    name: ts(site.name),
    url: getSiteUrl(site),
    description: getSiteDescription(t, ts, site),
    department: libraries.map((library) => getSchemaOrgLibrary(ts, library, site)),
    ...(siteImage?.asset ? { image: getSchemaOrgImageUrl(siteImage) } : {}),
  };
};
